import React from "react";

import logo from './../../assets/logo.png';

import './Header.css';

export const Header = () => {
  return (
    <header>
      <img src={logo} alt="Logo" />
    </header>
  );
};