import React from 'react';
import background from './assets/background.svg';
import './App.css';
import { Header } from './components/Header/Header';
import { Container } from 'reactstrap';

function App() {
  return (
    <>
    <Container>
      <Header />
      <section className="home-container">
        <div className="info-column">
          <h6>By Validated ID</h6>
          <h1>
            Your certified verifiable credentials with you wherever you go
          </h1>
          <p>
            With VIDchain we're offering secure identity solutions that allow a more digital
            and sustainable world guarateeing privacy, rights, and freedoms to people.
          </p>
          <div className='vid-chain-buttons-container'>
            <button className="sign-in-vid-chain"
              onClick={() => window.location.href = "https://app-vid-vccertbridge.azurewebsites.net/home"}>
              <span>Sign in with your certificate</span>
              <span>&rarr;</span>
            </button>
            <a className="sign-in-vid-chain" href="https://try.vidchain.net/demo/">
              <span>More info about VIDwallet</span>
              <span>&rarr;</span>
            </a>
          </div>
        </div>
        <div className="background-column">
          <img src={background} alt="Background" />
        </div>
      </section>
    </Container>
    </>
  );
}

export default App;
